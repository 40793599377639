import { RemoteCustomerGoals } from '../../../data/usecases/customer-goals/customer-goals'
import { makeAxiosHttpClient } from '../../factories/http/axios-http-client-factory-no-redirect'

export const makeRemoteCustomerGoals = ():any => new RemoteCustomerGoals('/meta', makeAxiosHttpClient())

export const makeRemoteCustomerGoalData = ():any => new RemoteCustomerGoals('/meta/item', makeAxiosHttpClient())

export const makeRemoteCustomerGoalDataProdutoEstrategico = ():any => new RemoteCustomerGoals('/meta/produtoestrategico', makeAxiosHttpClient())

export const makeRemoteCustomerGoalDataItem = ():any => new RemoteCustomerGoals('/meta/item/detalhe', makeAxiosHttpClient())

export const makeRemoteCustomerGoalDataProdutoEstrategicoItem = ():any => new RemoteCustomerGoals('/meta/produtoestrategico/detalhe', makeAxiosHttpClient())

export const makeRemoteDashboardEstrategicoItem = (numeroItem: string):any => new RemoteCustomerGoals(`/dashboard/meta/produtoestrategico/${numeroItem}`, makeAxiosHttpClient())

export const makeRemoteCustomerGoalDataExportItem = ():any => new RemoteCustomerGoals('/meta/export/detalhe', makeAxiosHttpClient())

export const makeRemoteCustomerGoalDataTotal = ():any => new RemoteCustomerGoals('/meta/total', makeAxiosHttpClient())

export const makeRemoteCustomerGoalDataStatus = ():any => new RemoteCustomerGoals('/meta/status', makeAxiosHttpClient())

export const makeRemoteCustomerGoalDataValue = ():any => new RemoteCustomerGoals('/meta/item/valor', makeAxiosHttpClient())

export const makeRemoteCustomerGoalDataProdutoEstrategicoValue = ():any => new RemoteCustomerGoals('/meta/produtoestrategico/valor', makeAxiosHttpClient())

export const makeRemoteCustomerGoalDataValueTotal = ():any => (
  new RemoteCustomerGoals('/meta/total/valor', makeAxiosHttpClient())
)

export const makeRemoteCustomerGoalDataApprove = ():any => new RemoteCustomerGoals('/meta/aprovacao', makeAxiosHttpClient())

export const makeRemoteCustomerGoalDataSendToApprove = ():any => new RemoteCustomerGoals('/meta/enviaraprovacao', makeAxiosHttpClient())

export const makeRemoteCustomerGoalDataReview = ():any => new RemoteCustomerGoals('/meta/revisar', makeAxiosHttpClient())

export const makeRemoteCustomerGoalDataDisapprove = ():any => new RemoteCustomerGoals('/meta/reprovacao', makeAxiosHttpClient())

export const makeUpdateCustomerPeople = ():any => new RemoteCustomerGoals('/meta/manager', makeAxiosHttpClient())
