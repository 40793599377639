/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-param-reassign, import/extensions, import/no-unresolved */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  makeRemoteActualDivision, makeRemoteDivision, makeRemoteDivisionByYear, makeRemoteDivisionParent,
} from 'src/main/usecases/users/users'

export interface DivisionsStateInterface {
  divisions: any,
  divisionsParent: any,
  loadingParent: any,
  loadedParent: any,
  loading: boolean,
  loaded: boolean,
}

interface DivisionsParamsInterface {
  parent?: string
}

interface DivisionsByYearParamsInterface {
  ano: string
}

// Define the initial state using that type
const initialState: DivisionsStateInterface = {
  divisions: [],
  divisionsParent: [],
  loading: true,
  loadingParent: true,
  loaded: false,
  loadedParent: false,
}

export const fetchDivisions = createAsyncThunk(
  'divisions/index',
  async (params?: DivisionsParamsInterface) => {
    const response = await makeRemoteDivision().index(params)

    return response
  },
)

export const fetchDivisionsByYear = createAsyncThunk(
  'divisions-by-year/index',
  async (params: DivisionsByYearParamsInterface) => {
    const response = await makeRemoteDivisionByYear(params.ano).index()

    return response
  },
)

export const fetchDivisionsParent = createAsyncThunk(
  'divisions-parent/parent',
  async (params: DivisionsParamsInterface) => {
    const response = await makeRemoteDivisionParent().index(params)
    return response
  },
)

export const fetchActualDivision = createAsyncThunk(
  'divisions-actual/actual',
  async (params?: DivisionsParamsInterface) => {
    const response = await makeRemoteActualDivision().index(params)
    return response
  },
)

const slice = createSlice({
  name: 'divisions',
  initialState,
  reducers: {
    divisionsLoaded(state) {
      state.loaded = true
    },
    divisionsLoading(state) {
      if (!state?.loading) {
        state.loading = true
      }
    },
    divisionsReceived(state) {
      if (state.loading) {
        state.loading = false
      }
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchDivisions.fulfilled, (state, action) => {
      state.divisions = action.payload
      state.loading = false
      state.loaded = true
    })
    builder.addCase(fetchDivisions.pending, (state) => {
      state.loading = true
      state.loaded = false
    })
    builder.addCase(fetchDivisions.rejected, (state) => {
      state.loading = false
      state.loaded = false
    })
    builder.addCase(fetchDivisionsByYear.fulfilled, (state, action) => {
      state.divisions = action.payload
      state.loading = false
      state.loaded = true
    })
    builder.addCase(fetchDivisionsByYear.pending, (state) => {
      state.loading = true
      state.loaded = false
    })
    builder.addCase(fetchDivisionsByYear.rejected, (state) => {
      state.loading = false
      state.loaded = false
    })
    builder.addCase(fetchDivisionsParent.fulfilled, (state, action) => {
      state.divisionsParent = action.payload
      state.loadingParent = false
      state.loadedParent = true
    })
    builder.addCase(fetchDivisionsParent.pending, (state) => {
      state.loadingParent = true
      state.loadedParent = false
    })
    builder.addCase(fetchDivisionsParent.rejected, (state) => {
      state.loadingParent = false
      state.loadedParent = false
    })
    builder.addCase(fetchActualDivision.fulfilled, (state, action) => {
      state.divisions = action.payload
      state.loading = false
      state.loaded = true
    })
    builder.addCase(fetchActualDivision.pending, (state) => {
      state.loading = true
      state.loaded = false
    })
    builder.addCase(fetchActualDivision.rejected, (state) => {
      state.loading = false
      state.loaded = false
    })
  },
})

export const {
  divisionsLoaded,
  divisionsLoading,
  divisionsReceived,
} = slice.actions

export default slice.reducer
