/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-param-reassign, import/extensions, import/no-unresolved */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { makeRemoteClients } from 'src/main/usecases/clients/clients'

export interface ClientsStateInterface {
  clients: any,
  loading: boolean,
  loaded: boolean,
  size?: number,
  page?: number,
  totalPages?: number,
  totalElements?: number,
  filterString?: string,
  classification?: string,
  participacaoLiga?: string,
  filterType?: string
  sort?: string,
  order?: string
}

// Define the initial state using that type
const initialState: ClientsStateInterface = {
  clients: [],
  loading: true,
  loaded: false,
}

export interface ClientParamsInterface {
  page: number,
  size: number,
  sort?: string,
  filterString?: string,
  filterType?: string
  classification?: string
  participacaoLiga?: string
}

export const fetchClients = createAsyncThunk(
  'clients/index',
  async (params: ClientParamsInterface) => {
    const response = await makeRemoteClients().index(params)
    return response
  },
)

const slice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    clientsLoaded(state) {
      state.loaded = true
    },
    clientsLoading(state) {
      if (!state?.loading) {
        state.loading = true
      }
    },
    clientsReceived(state) {
      if (state.loading) {
        state.loading = false
      }
    },
    clientsFilter(state, action) {
      state.filterType = action.payload.filterType
      state.filterString = action.payload.filterString
      state.classification = action.payload.classification
      state.participacaoLiga = action.payload.participacaoLiga
    },
    clientsFilterSort(state, action) {
      state.sort = action.payload
    },
    clientsFilterOrder(state, action) {
      state.order = action.payload
    },
    clientsOrderAndSort(state, action) {
      state.order = action.payload.order
      state.sort = action.payload.sort
    },
    clientsClearSort(state) {
      state.sort = undefined
      state.order = undefined
      state.classification = undefined
    },
    clientsClearClassification(state) {
      state.classification = undefined
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchClients.fulfilled, (state, action) => {
      state.clients = action.payload.content
      state.size = action.payload.size
      state.page = action.payload.number
      state.totalPages = action.payload.totalPages
      state.totalElements = action.payload.totalElements
      state.loading = false
    })
    builder.addCase(fetchClients.rejected, (state) => {
      state.loading = false
      state.clients = []
    })
    builder.addCase(fetchClients.pending, (state) => {
      state.loading = true
    })
  },
})

export const {
  clientsLoaded,
  clientsLoading,
  clientsReceived,
  clientsFilter,
  clientsFilterSort,
  clientsFilterOrder,
  clientsClearSort,
  clientsClearClassification,
  clientsOrderAndSort,
} = slice.actions

export default slice.reducer
