/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable import/extensions, import/no-unresolved */
import Qs from 'qs'
import moment from 'moment'
import fileDownload from 'js-file-download'
import { HttpStatusCode } from '../../protocols/http/http-client'
import { UnauthorizedError } from '../../../domain/errors/unauthorized-error'
import { AccessDeniedError } from '../../../domain/errors/access-denied-error'
import { UnexpectedError } from '../../../domain/errors/unexpected-error'

export class RemoteDashboard {
  url: string

  httpClient: any

  constructor(url: string, httpClient: any) {
    this.url = url
    this.httpClient = httpClient
  }

  async load(params?: any) {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'get',
      paramsSerializer(paramsAux: any) {
        return Qs.stringify(paramsAux, { arrayFormat: 'repeat' })
      },
      params,
    })

    let tempData
    if (httpResponse.statusCode !== 200) {
      tempData = httpResponse.body.length > 0 ? httpResponse.body[0].msgUsuario : 'Erro na requisição'
    }

    switch (httpResponse.statusCode) {
      case HttpStatusCode.unauthorized: throw new UnauthorizedError()
      case HttpStatusCode.forbidden: throw new AccessDeniedError()
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.badRequest: throw new Error(tempData)

      default: throw new UnexpectedError()
    }
  }

  async export(name: string, params?: any) {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/export`,
      method: 'get',
      responseType: 'arraybuffer',
      headers: {
        Accept: 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9',
      },
      paramsSerializer(paramsAux: any) {
        return Qs.stringify(paramsAux, { arrayFormat: 'repeat' })
      },
      params,
    })

    let tempData
    if (httpResponse.statusCode !== 200) {
      tempData = httpResponse.body.length > 0 ? httpResponse.body[0].msgUsuario : 'Erro na requisição'
    }

    switch (httpResponse.statusCode) {
      case HttpStatusCode.unauthorized: throw new UnauthorizedError()
      case HttpStatusCode.forbidden: throw new AccessDeniedError()
      case HttpStatusCode.badRequest: throw new Error(tempData)
      case HttpStatusCode.serverError: throw new Error(tempData)
      case HttpStatusCode.ok:
        fileDownload(httpResponse.body, `${name}_${moment(new Date()).format('yyyy-MM-DD')}.xlsx`)
        return httpResponse.body
      default: throw new UnexpectedError()
    }
  }
}
