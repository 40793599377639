/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
import React from 'react'
import { Field, FieldProps } from 'formik'
import Select from 'react-select'
import { theme } from 'src/presentation/styles/styles'
import AsyncSelect from 'react-select/async'
import TplGroup from './tpl_group'
import { FormSelect } from '../form'
import { Flex } from '../flex'

interface Option {
  value: string,
  label: string,
}

interface SelectOptionProps {
  name: string,
  title: string,
  disabled?: boolean,
  options: Option[] | [],
  tabIndex: number,
  maxChecked: number,
  componentAction?: any,
  checked: boolean,
  value: Option[] | [],
  setFieldValue: (name: string, value: any[]) => void,
  hidden: boolean,
  asyncFunction?: () => Promise<any>,
  defaultAsyncValue?: any,
  isRequired: boolean,
  isMulti?: boolean,
  placeholder?: string,
  noOptionsMessage?: string,
  values: any,
  onChange?: (value: string) => void,
  components?: any,
  defaultOptions?: Option[] | [],
  byGetValue?: boolean,
  errors: {
    [key: string]: string
  }
  control?: any
  [key: string]: any
}

const SelectOption = ({
  name, title, isRequired, options = [], tabIndex, hidden, ...el
}: SelectOptionProps): JSX.Element => {
  const handleChange = (event: any): void => {
    if (el.onChange) {
      if (el.isMulti) {
        el.onChange(event)
      } else {
        el.onChange(event.value)
      }
    }
  }

  const getValues = (): Option | any => {
    if (el.isMulti) {
      return el?.values[name]
    }
    return options.find((item) => item.value === el?.values[name])
  }

  if (hidden && options.length === 1) {
    return <Field type="hidden" name={name} value={options[0].value} />
  }

  return (
    <Flex>
      <TplGroup name={name} title={title} isRequired={isRequired} {...el}>
        <Field
          disabled={el.disabled || false}
          as={FormSelect}
          required={isRequired}
          name={name}
          tabIndex={tabIndex}
          onChange={handleChange}
        >
          {
            ({ field }: FieldProps) => (
              el.asyncFunction
                ? (
                  <AsyncSelect
                    cacheOptions
                    defaultOptions={el.defaultOptions}
                    loadingMessage={() => <span>Carregando...</span>}
                    loadOptions={el.asyncFunction}
                    styles={{
                      control: (base, actions) => ({
                        ...base,
                        borderRadius: 10,
                        borderWidth: 0.5,
                        borderColor: actions.isDisabled ? '#c1c1c1' : '#868686',
                        boxShadow: undefined,
                        paddingTop: 5,
                        paddingBottom: 5,
                        'max-height': 45,
                        backgroundColor: actions.isDisabled ? '#ebebeb' : 'transparent',
                        ':hover': {
                          borderColor: '#868686',
                        },
                        ...el.control,
                      }),
                      menu: (base) => ({ ...base, borderRadius: 8 }),
                      placeholder: (base) => ({
                        ...base,
                        fontSize: theme.fontSizes.uxs,
                      }),
                      option: (base) => ({
                        ...base,
                        fontSize: theme.fontSizes.uxs,
                      }),
                      singleValue: (base) => ({
                        ...base,
                        fontSize: theme.fontSizes.uxs,
                      }),
                      indicatorSeparator: (base) => ({
                        ...base,
                        display: 'none',
                      }),
                    }}
                    {...field}
                    {...el}
                    value={el.byGetValue ? getValues() : el.value}
                    placeholder={el.placeholder}
                    noOptionsMessage={() => <span>Não encontrado</span>}
                    blurInputOnSelect={false}
                    openMenuOnFocus={false}
                    isDisabled={!!el.disabled}
                    onChange={(event: any) => {
                      handleChange(event)
                      if (el.setFieldValue) {
                        if (el.isMulti) {
                          el.setFieldValue(name, event)
                        } else {
                          el.setFieldValue(name, event?.value)
                          el.setFieldTouched()
                        }
                      }
                    }}
                    name={name}
                  />
                )
                : (
                  <Select
                    isSearchable
                    styles={{
                      control: (base, actions) => ({
                        ...base,
                        borderRadius: 10,
                        borderWidth: 0.5,
                        borderColor: actions.isDisabled ? '#c1c1c1' : '#868686',
                        backgroundColor: actions.isDisabled ? '#ebebeb' : 'transparent',
                        boxShadow: undefined,
                        paddingTop: 5,
                        paddingBottom: 5,
                        'max-height': 45,
                        ':hover': {
                          borderColor: '#868686',
                        },
                        ...el.control,
                      }),
                      menu: (base) => ({ ...base, borderRadius: 8 }),
                      placeholder: (base) => ({
                        ...base,
                        fontSize: theme.fontSizes.uxs,
                      }),
                      option: (base) => ({
                        ...base,
                        fontSize: theme.fontSizes.uxs,
                      }),
                      singleValue: (base) => ({
                        ...base,
                        fontSize: theme.fontSizes.uxs,
                      }),
                      indicatorSeparator: (base) => ({
                        ...base,
                        display: 'none',
                      }),

                    }}
                    {...field}
                    {...el}
                    options={options}
                    placeholder={el.placeholder}
                    noOptionsMessage={() => <span>Não encontrado</span>}
                    blurInputOnSelect={false}
                    openMenuOnFocus={false}
                    loadingMessage={() => <span>Carregando...</span>}
                    isDisabled={!!el.disabled}
                    value={getValues()}
                    onChange={(event: any) => {
                      handleChange(event)
                      if (el.setFieldValue) {
                        if (el.isMulti) {
                          el.setFieldValue(name, event)
                        } else {
                          el.setFieldValue(name, event?.value)
                        }
                      }
                    }}
                    name={name}
                  />
                )
            )
          }
        </Field>
      </TplGroup>

      {!!el?.componentAction && el.componentAction}
    </Flex>
  )
}

export default SelectOption
