/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-param-reassign, import/extensions, import/no-unresolved */
import { currentYear } from 'src/presentation/utils/dates'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  makeDashboardFiltroPeriodo,
  makeDasboardMarca,
  makeDashboardNacional,
  makeDashboardRegional,
  makeDashboardVendedor,
  makeDashboardCliente,
  makeDashboardTipo,
  makeDashboardGrupo,
  makeDashboardFiltroDivisao,
} from 'src/main/usecases/dashboard/dashboard-report'

interface TypeInterface {
  id: number,
  nome: string,
  tipoclientes: string[]
}

export interface DashboardReportStateInterface {
  loading: boolean;
  loaded: boolean;
  divisoes: any[],
  years: any[],
  groupsClients: any[],
  nacional: any[],
  regional: any[],
  vendedor: any[],
  cliente: any[],
  canais: TypeInterface[],
  marca: any[],
}

// Define the initial state using that type
const initialState: DashboardReportStateInterface = {
  loading: true,
  divisoes: [],
  years: [{ ano: currentYear }],
  loaded: false,
  groupsClients: [],
  nacional: [],
  regional: [],
  vendedor: [],
  canais: [],
  cliente: [],
  marca: [],
}

export interface ParamsReportInterface {
  codigoGrupo?: string[] | null,
  ano?: number | null;
  divisao?: string[] | null;
  trimestre?: number | null;
  nacional?: string,
  regional?: string,
  vendedor?: string,
  numeroItem?: number | null;
  codigoJDE?: number[]
  codigoJDENacional?: number[]
  codigoJDERegional?: number[]
  codigoJDEVendedor?: number[]
  mes?: number[]
  marca?: string[]
  tipoId?: number[]
}

export const fetchReportDivisions = createAsyncThunk(
  'dashboard/report/divisoes',
  async (params?: ParamsReportInterface) => {
    const response = await makeDashboardFiltroDivisao().load(params)

    return response
  },
)

export const fetchReportYears = createAsyncThunk(
  'dashboard/report/years',
  async () => {
    const response = await makeDashboardFiltroPeriodo().load()

    return response
  },
)

export const fetchReportCanaisDash = createAsyncThunk(
  'dashboard/report/canais',
  async (params?: ParamsReportInterface) => {
    const response = await makeDashboardTipo().load(params)

    return response
  },
)

export const fetchReportMarcaDash = createAsyncThunk(
  'dashboard/report/marca',
  async (params?: ParamsReportInterface) => {
    const response = await makeDasboardMarca().load(params)

    return response
  },
)

export const fetchReportManagerNacional = createAsyncThunk(
  'dashboard/report/nacional',
  async (params: ParamsReportInterface) => {
    const response = await makeDashboardNacional().load(params)

    return response
  },
)

export const fetchReportManagerRegional = createAsyncThunk(
  'dashboard/report/regional',
  async (params: ParamsReportInterface) => {
    const response = await makeDashboardRegional().load(params)

    return response
  },
)

export const fetchReportManagerVendedor = createAsyncThunk(
  'dashboard/report/vendedor',
  async (params: ParamsReportInterface) => {
    const response = await makeDashboardVendedor().load(params)

    return response
  },
)

export const fetchReportManagerClient = createAsyncThunk(
  'dashboard/report/client',
  async (params: ParamsReportInterface) => {
    const response = await makeDashboardCliente().load(params)

    return response
  },
)

export const fetchReportGroupClients = createAsyncThunk(
  'dashboard/meta/grupo-clients',
  async (params: ParamsReportInterface) => {
    const response = await makeDashboardGrupo().load(params)

    return response
  },
)

const slice = createSlice({
  name: 'dashboard-report',
  initialState,
  reducers: {
    dashboardReportLoaded(state: any) {
      state.loaded = true
    },
    dashboardReportLoading(state: any) {
      if (!state?.loading) {
        state.loading = true
      }
    },
    dashboardReportReceived(state: any) {
      if (state.loading) {
        state.loading = false
      }
    },
    dashboardReportReset(state: any) {
      state.years = [{
        ano: currentYear,
      }]
      state.divisoes = []
      state.nacional = []
      state.regional = []
      state.vendedor = []
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchReportYears.fulfilled, (state, action) => {
      state.years = action.payload
    })
    builder.addCase(fetchReportDivisions.fulfilled, (state, action) => {
      state.divisoes = action.payload
    })
    builder.addCase(fetchReportManagerNacional.fulfilled, (state, action) => {
      state.nacional = action.payload
    })
    builder.addCase(fetchReportManagerRegional.fulfilled, (state, action) => {
      state.regional = action.payload
    })
    builder.addCase(fetchReportManagerVendedor.fulfilled, (state, action) => {
      state.vendedor = action.payload
    })
    builder.addCase(fetchReportManagerClient.fulfilled, (state, action) => {
      state.cliente = action.payload || []
    })
    builder.addCase(fetchReportCanaisDash.fulfilled, (state, action) => {
      state.canais = action.payload
    })
    builder.addCase(fetchReportMarcaDash.fulfilled, (state, action) => {
      state.marca = action.payload
    })
    builder.addCase(fetchReportGroupClients.fulfilled, (state, action) => {
      state.groupsClients = action.payload
    })
  },
})

export const {
  dashboardReportLoaded,
  dashboardReportLoading,
  dashboardReportReceived,
  dashboardReportReset,
} = slice.actions

export default slice.reducer
