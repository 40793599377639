/* eslint-disable import/extensions, import/no-unresolved */

import { RemoteDashboard } from '../../../data/usecases/dashboard/dashboard'
import { makeAxiosHttpClient } from '../../factories/http/axios-http-client-factory'
import { makeAxiosHttpClient as makeAxiosNoRedirect } from '../../factories/http/axios-http-client-factory-no-redirect'

export const makeRemoteGoalxAccomplishedChart = (): any => new RemoteDashboard('/dashboard/meta/metarealizado', makeAxiosHttpClient())

export const makeRemotePerformanceAnalysisChart = (): any => new RemoteDashboard('/dashboard/meta/analiseperformance', makeAxiosHttpClient())

export const makeRemoteHistoricChart = (): any => new RemoteDashboard('/dashboard/meta/historico', makeAxiosHttpClient())

export const makeRemoteCalculationQuery = (): any => new RemoteDashboard('/dashboard/meta/apuracao', makeAxiosNoRedirect())

export const makeRemoteQuarterlyQuotas = (): any => new RemoteDashboard('/dashboard/meta/cotas', makeAxiosHttpClient())

export const makeRemoteMonthQuotas = (): any => new RemoteDashboard('/dashboard/meta/cotasmensal', makeAxiosHttpClient())
export const makeRemoteStrategicProducts = (): any => new RemoteDashboard('/dashboard/meta/produtoestrategico', makeAxiosNoRedirect())

export const makeRemoteMixProducts = (): any => new RemoteDashboard('/dashboard/meta/metamixproduto', makeAxiosNoRedirect())

export const makeRemoteDashboardKpi = (): any => new RemoteDashboard('/dashboard/kpi', makeAxiosHttpClient())

export const makeRemoteDashboardKpiHeader = (): any => new RemoteDashboard('/dashboard/kpi/cabecalho', makeAxiosHttpClient())

export const makeRemoteDashboardKpiItem = (id:number): any => new RemoteDashboard(`/dashboard/kpi/${id}`, makeAxiosHttpClient())

export const makeDashboardFiltroGrupo = (): any => new RemoteDashboard('/dashboard/filtro/grupo', makeAxiosHttpClient())

export const makeDashboardFiltroDivisao = (): any => new RemoteDashboard('/dashboard/filtro/divisao', makeAxiosHttpClient())

export const makeDashboardFiltroPeriodo = (): any => (
  new RemoteDashboard('/dashboard/filtro/configuracaoperiodo', makeAxiosHttpClient())
)

export const makeDashboardNacional = (): any => new RemoteDashboard('/pessoa/nacional', makeAxiosNoRedirect())
export const makeDashboardRegional = (): any => new RemoteDashboard('/pessoa/regional', makeAxiosNoRedirect())
export const makeDashboardVendedor = (): any => new RemoteDashboard('/pessoa/vendedor', makeAxiosNoRedirect())
export const makeDashboardCliente = (): any => new RemoteDashboard('/pessoa/cliente', makeAxiosNoRedirect())

export const makeDashboardKpiItems = (): any => new RemoteDashboard('/dashboard/filtro/kpi', makeAxiosHttpClient())

export const makeDashboardTipo = (): any => new RemoteDashboard('/dashboard/cota/filtro/tipo', makeAxiosHttpClient())
