/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable import/extensions, import/no-unresolved */
import Qs from 'qs'
import fileDownload from 'js-file-download'
import moment from 'moment'
import { NotFoundError } from 'src/domain/errors/not-found-error'
import { HttpStatusCode } from '../../protocols/http/http-client'
import { UnauthorizedError } from '../../../domain/errors/unauthorized-error'
import { AccessDeniedError } from '../../../domain/errors/access-denied-error'
import { UnexpectedError } from '../../../domain/errors/unexpected-error'

export class RemoteCustomerGoals {
  url: string

  httpClient: any

  constructor(url: string, httpClient: any) {
    this.url = url
    this.httpClient = httpClient
  }

  async index(params?: any) {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'get',
      params,
      paramsSerializer(paramsAux: any) {
        return Qs.stringify(paramsAux, { arrayFormat: 'repeat' })
      },
    })

    const message = httpResponse.body.length > 0 ? httpResponse.body[0]?.msgUsuario : ''

    switch (httpResponse.statusCode) {
      case HttpStatusCode.unauthorized: throw new UnauthorizedError()
      case HttpStatusCode.notFound:
        if (message) throw new Error(message)
        throw new NotFoundError()
      case HttpStatusCode.forbidden: throw new AccessDeniedError()
      case HttpStatusCode.ok: return httpResponse.body
      default: throw new UnexpectedError()
    }
  }

  async create(data: any, params?: any) {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      data,
      params,
    })

    const message = httpResponse.body.length > 0 ? httpResponse.body[0]?.msgUsuario : 'Erro na requisição'

    switch (httpResponse.statusCode) {
      case HttpStatusCode.unauthorized: throw new UnauthorizedError()
      case HttpStatusCode.forbidden: throw new AccessDeniedError()
      case HttpStatusCode.badRequest: throw new Error(message)
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.noContent: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      default: throw new UnexpectedError()
    }
  }

  async update(data: any, params?: any) {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'put',
      data,
      params,
    })

    const message = httpResponse.body.length > 0 ? httpResponse.body[0]?.msgUsuario : 'Erro na requisição'

    switch (httpResponse.statusCode) {
      case HttpStatusCode.unauthorized: throw new UnauthorizedError()
      case HttpStatusCode.forbidden: throw new AccessDeniedError()
      case HttpStatusCode.badRequest: throw new Error(message)
      case HttpStatusCode.notFound: throw new Error(message)
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.noContent: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      default: throw new UnexpectedError()
    }
  }

  async delete(body: any) {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'delete',
      data: body,
    })

    const message = httpResponse.body.length > 0 ? httpResponse.body[0]?.msgUsuario : 'Erro na requisição'

    switch (httpResponse.statusCode) {
      case HttpStatusCode.unauthorized: throw new UnauthorizedError()
      case HttpStatusCode.forbidden: throw new AccessDeniedError()
      case HttpStatusCode.badRequest: throw new Error(message)
      case HttpStatusCode.notFound: throw new Error(message)
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.noContent: return httpResponse.body
      case HttpStatusCode.created: return httpResponse.body
      default: throw new UnexpectedError()
    }
  }

  async export(url: string, title: string, params?: any) {
    const httpResponse = await this.httpClient.request({
      url: url || `${this.url}/export`,
      method: 'get',
      responseType: 'arraybuffer',
      headers: {
        Accept: 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9',
      },
      paramsSerializer(paramsAux: any) {
        return Qs.stringify(paramsAux, { arrayFormat: 'repeat' })
      },
      params,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.unauthorized: throw new UnauthorizedError()
      case HttpStatusCode.forbidden: throw new AccessDeniedError()
      case HttpStatusCode.ok:
        fileDownload(httpResponse.body, `${title}_${moment(new Date()).format('yyyy-MM-DD')}.xlsx`)
        return httpResponse.body
      default: throw new UnexpectedError()
    }
  }
}
