import { USERS_TYPES as S } from './users'

const ALL_USERS = Object.values(S)

interface PagePermissionInterface {
  [key: string]: any
}

/*
  ********** PERMISSIONS **********

  {} -> canAccess, canEdit, canExport

  {
    access: [], -> Users that can Access,
    export: [], -> Users that can Export,
    edit: [], -> Usesr that can Edit,
  }

  if has no attribute, means that can do that action

  ex: {
    export: [S.ADMINISTRADOR]
  }

  -> ALL can Edit
  -> ALL can Access
  -> Only ADMIN can Export

  It has a permission-helper in src/presentation/helpers to help us in this job

*/

export const PERMISSIONS: PagePermissionInterface = {
  '/login': {},
  '/recuperar-senha': {},
  '/403': {},
  '/404': {},
  '/500': {},
  '/sair': {},
  '/': {
    access: ALL_USERS,
  },
  '/dashboard-metas': {
    export: [...ALL_USERS]
      .filter((user) => ![S.CLIENTE].includes(user)),
    access: ALL_USERS,
  },
  '/dashboard-kpi': {
    export: [...ALL_USERS]
      .filter((user) => ![S.CLIENTE].includes(user)),
    access: ALL_USERS,
  },
  '/dashboard-relatorio': {
    access: [...ALL_USERS]
      .filter((user) => ![S.CLIENTE].includes(user)), // except CLIENTE
  },
  '/dashboard-cotas-metas': {
    access: [...ALL_USERS]
      .filter((user) => ![S.CLIENTE].includes(user)),
  },
  '/usuarios': {
    access: [S.ADMINISTRADOR, S.AUDITORIA],
    edit: [S.ADMINISTRADOR],
    export: [S.ADMINISTRADOR],
  },
  '/grupo-aprovacoes': {
    access: [S.ADMINISTRADOR, S.AUDITORIA],
    edit: [S.ADMINISTRADOR],
    export: ALL_USERS,
  },
  '/consulta-clientes': {
    access: [...ALL_USERS]
      .filter((user) => ![S.CLIENTE, S.VENDEDOR, S.REGIONAL].includes(user)), // except CLIENTE
    edit: [S.ADMINISTRADOR],
    export: ALL_USERS,
  },
  '/regras-programa': {},
  '/cotas': {
    access: [...ALL_USERS]
      .filter((user) => ![S.CLIENTE].includes(user)), // except CLIENTE
    edit: [...ALL_USERS]
      .filter((user) => ![S.MARKETING, S.AUDITORIA, S['KEY ACCOUNT']]
        .includes(user)), // except MARKETING, AUDITORIA, KEY ACCOUNT
    export: ALL_USERS,
  },
  '/cotas-bu': {
    access: [...ALL_USERS]
      .filter((user) => ![S.CLIENTE].includes(user)), // except CLIENTE
    edit: [...ALL_USERS]
      .filter((user) => ![S.MARKETING, S.AUDITORIA, S['KEY ACCOUNT']]
        .includes(user)), // except MARKETING, AUDITORIA, KEY ACCOUNT
    export: ALL_USERS,
  },
  '/cotas/:gerente': {
    regex: /\/cotas\/[A-Za-z]+/gm,
    access: [...ALL_USERS]
      .filter((user) => ![S.CLIENTE].includes(user)), // except CLIENTE
    edit: [...ALL_USERS]
      .filter((user) => ![S.MARKETING, S.AUDITORIA, S['KEY ACCOUNT']]
        .includes(user)), // except MARKETING, AUDITORIA, KEY ACCOUNT
    export: ALL_USERS,
  },
  '/apuracoes-metas': {
    access: [S.ADMINISTRADOR, S.AUDITORIA],
    edit: [S.ADMINISTRADOR],
    export: ALL_USERS,
  },
  '/classificacoes': {
    access: [S.ADMINISTRADOR, S.AUDITORIA],
    edit: [S.ADMINISTRADOR],
    export: ALL_USERS,
  },
  '/meta-cliente': {
    access: [...ALL_USERS]
      .filter((user) => ![S.CLIENTE].includes(user)), // except CLIENTE
    edit: [...ALL_USERS]
      .filter((user) => ![S.MARKETING, S.AUDITORIA, S['KEY ACCOUNT']]
        .includes(user)), // except MARKETING, AUDITORIA, KEY ACCOUNT
    export: ALL_USERS,
  },
  '/adicionar-meta': {
    access: [...ALL_USERS]
      .filter((user) => ![S.MARKETING, S.AUDITORIA, S['KEY ACCOUNT']]
        .includes(user)), // except MARKETING, AUDITORIA, KEY ACCOUNT
    edit: [...ALL_USERS]
      .filter((user) => ![S.MARKETING, S.AUDITORIA, S['KEY ACCOUNT']]
        .includes(user)), // except MARKETING, AUDITORIA, KEY ACCOUNT
    export: [...ALL_USERS]
      .filter((user) => ![S.MARKETING, S.AUDITORIA, S['KEY ACCOUNT']]
        .includes(user)), // except MARKETING, AUDITORIA, KEY ACCOUNT
  },
  '/meta-cliente/:configuracaoPeriodoId/:divisao/:id': {
    regex: /\/meta-cliente\/[0-9]+\/[A-Z]+\/[0-9]+/gm,
    access: [...ALL_USERS]
      .filter((user) => ![S.CLIENTE].includes(user)), // except CLIENTE
    edit: [...ALL_USERS]
      .filter((user) => ![S.MARKETING, S.AUDITORIA, S['KEY ACCOUNT']]
        .includes(user)), // except MARKETING, AUDITORIA, KEY ACCOUNT
    export: ALL_USERS,
  },
  '/distribuidores': {
    access: [S.ADMINISTRADOR, S.AUDITORIA],
    edit: [S.ADMINISTRADOR],
    export: ALL_USERS,
  },
  '/configuracoes/distribuidores': {
    access: [S.ADMINISTRADOR, S.AUDITORIA],
    edit: [S.ADMINISTRADOR],
    export: ALL_USERS,
  },
  '/produtos-para-bonificacao': {
    access: [...ALL_USERS]
      .filter((user) => ![S.CLIENTE].includes(user)), // except CLIENTE
    edit: [S.ADMINISTRADOR, S.MARKETING],
    export: ALL_USERS,
  },
  '/configuracoes/desconto-em-duplicata': {
    access: [S.ADMINISTRADOR, S.AUDITORIA],
    edit: [S.ADMINISTRADOR],
    export: ALL_USERS,
  },
  '/configuracoes/regras-programa': {
    access: [S.ADMINISTRADOR, S.AUDITORIA],
    edit: [S.ADMINISTRADOR],
    export: ALL_USERS,
  },
  '/configuracoes/recuperacao-anual': {
    access: [S.ADMINISTRADOR, S.AUDITORIA],
    edit: [S.ADMINISTRADOR],
    export: ALL_USERS,
  },
  '/importacao': {
    access: [S.ADMINISTRADOR, S.AUDITORIA],
    edit: [S.ADMINISTRADOR],
    export: ALL_USERS,
  },
  '/gerar-apuracao': {
    access: [S.ADMINISTRADOR, S.AUDITORIA],
    edit: [S.ADMINISTRADOR],
    export: ALL_USERS,
  },
  '/atualizar-apuracao': {
    access: [S.ADMINISTRADOR, S.AUDITORIA],
    edit: [S.ADMINISTRADOR],
    export: ALL_USERS,
  },
  '/pedido-resgate': {
    access: [...ALL_USERS]
      .filter((user) => ![S.CLIENTE].includes(user)), // except CLIENTE
    edit: [S.NACIONAL, S.REGIONAL, S.VENDEDOR, S.VTC],
    export: ALL_USERS,
  },
  '/aprovacao-pedido': {
    access: [S.ADMINISTRADOR, S.MARKETING, S.AUDITORIA, S.REGIONAL],
    edit: [S.MARKETING, S.REGIONAL],
    export: ALL_USERS,
  },
  '/saldos': {
    access: [...ALL_USERS]
      .filter((user) => ![S.CLIENTE].includes(user)), // except CLIENTE
    edit: [S.ADMINISTRADOR],
    export: ALL_USERS,
  },
  '/aceite-regras-programa': {
    access: [S.MARKETING, S.ADMINISTRADOR], // except CLIENTE
    edit: [S.ADMINISTRADOR],
    export: [S.ADMINISTRADOR],
    onlyDev: true,
  },
}
