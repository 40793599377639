/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable import/extensions, import/no-unresolved */

import { HttpStatusCode } from '../../protocols/http/http-client'
import { NotFoundError } from '../../../domain/errors/not-found-error'
import { UnauthorizedError } from '../../../domain/errors/unauthorized-error'
import { AccessDeniedError } from '../../../domain/errors/access-denied-error'
import { UnexpectedError } from '../../../domain/errors/unexpected-error'

export class RemoteImportSpreadsheet {
  url: string

  httpClient: any

  constructor(url: string, httpClient: any) {
    this.url = url
    this.httpClient = httpClient
  }

  async create(params: any, data: any) {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      params,
      data,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.unauthorized: throw new UnauthorizedError()
      case HttpStatusCode.notFound: throw new NotFoundError()
      case HttpStatusCode.badRequest: throw new Error('Erro na requisição.')
      case HttpStatusCode.forbidden: throw new AccessDeniedError()
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.serverError: throw new Error('Dados com formato incorreto.')
      default: throw new UnexpectedError()
    }
  }

  async index(params: any) {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'get',
      params,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.unauthorized: throw new UnauthorizedError()
      case HttpStatusCode.notFound: throw new NotFoundError()
      case HttpStatusCode.badRequest: throw new Error('Erro na requisição.')
      case HttpStatusCode.forbidden: throw new AccessDeniedError()
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.ok: return httpResponse.body
      default: throw new UnexpectedError()
    }
  }
}
