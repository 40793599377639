/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-param-reassign, import/extensions, import/no-unresolved */
import { currentYear } from 'src/presentation/utils/dates'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  makeDashboardFiltroPeriodo,
  makeDashboardFiltroDivisao,
  makeDasboardMarca,
  makeDashboardNacional,
  makeDashboardRegional,
  makeDashboardVendedor,
  makeDashboardTipo,
  makeDashboardGrupo,
} from 'src/main/usecases/dashboard/dashboard-validation'
import { TFilterParams } from '@interfaces/models/dashboards-report'

interface TypeInterface {
  id: number,
  nome: string,
  tipoclientes: string[]
}

export interface DashboardValidationStateInterface {
  loading: boolean;
  loaded: boolean;
  divisoes: any[],
  years: any[],
  groupsClients: any[],
  nacional: any[],
  regional: any[],
  vendedor: any[],
  canais: TypeInterface[],
  marca: any[],
}

// Define the initial state using that type
const initialState: DashboardValidationStateInterface = {
  loading: true,
  divisoes: [],
  years: [{ ano: currentYear }],
  loaded: false,
  groupsClients: [],
  nacional: [],
  regional: [],
  vendedor: [],
  canais: [],
  marca: [],
}

export const fetchValidationDivisions = createAsyncThunk(
  'dashboard/validation/divisoes',
  async (params?: TFilterParams) => {
    const response = await makeDashboardFiltroDivisao().load(params)

    return response
  },
)

export const fetchValidationYears = createAsyncThunk(
  'dashboard/validation/years',
  async () => {
    const response = await makeDashboardFiltroPeriodo().load()

    return response
  },
)

export const fetchValidationCanaisDash = createAsyncThunk(
  'dashboard/validation/canais',
  async (params?: TFilterParams) => {
    const response = await makeDashboardTipo().load(params)

    return response
  },
)

export const fetchValidationMarcaDash = createAsyncThunk(
  'dashboard/validation/marca',
  async (params?: TFilterParams) => {
    const response = await makeDasboardMarca().load(params)

    return response
  },
)

export const fetchValidationManagerNacional = createAsyncThunk(
  'dashboard/validation/nacional',
  async (params: TFilterParams) => {
    const response = await makeDashboardNacional().load(params)

    return response
  },
)

export const fetchValidationManagerRegional = createAsyncThunk(
  'dashboard/validation/regional',
  async (params: TFilterParams) => {
    const response = await makeDashboardRegional().load(params)

    return response
  },
)

export const fetchValidationManagerVendedor = createAsyncThunk(
  'dashboard/validation/vendedor',
  async (params: TFilterParams) => {
    const response = await makeDashboardVendedor().load(params)

    return response
  },
)

export const fetchValidationGroupClients = createAsyncThunk(
  'dashboard/meta/grupo-clients',
  async (params: TFilterParams) => {
    const response = await makeDashboardGrupo().load(params)

    return response
  },
)

const slice = createSlice({
  name: 'dashboard-validation',
  initialState,
  reducers: {
    dashboardValidationLoaded(state: any) {
      state.loaded = true
    },
    dashboardValidationLoading(state: any) {
      if (!state?.loading) {
        state.loading = true
      }
    },
    dashboardValidationReceived(state: any) {
      if (state.loading) {
        state.loading = false
      }
    },
    dashboardValidationReset(state: any) {
      state.years = [{
        ano: currentYear,
      }]
      state.divisoes = []
      state.nacional = []
      state.regional = []
      state.vendedor = []
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchValidationYears.fulfilled, (state, action) => {
      state.years = action.payload
    })
    builder.addCase(fetchValidationDivisions.fulfilled, (state, action) => {
      state.divisoes = action.payload
    })
    builder.addCase(fetchValidationManagerNacional.fulfilled, (state, action) => {
      state.nacional = action.payload
    })
    builder.addCase(fetchValidationManagerRegional.fulfilled, (state, action) => {
      state.regional = action.payload
    })
    builder.addCase(fetchValidationManagerVendedor.fulfilled, (state, action) => {
      state.vendedor = action.payload
    })
    builder.addCase(fetchValidationCanaisDash.fulfilled, (state, action) => {
      state.canais = action.payload
    })
    builder.addCase(fetchValidationMarcaDash.fulfilled, (state, action) => {
      state.marca = action.payload
    })
    builder.addCase(fetchValidationGroupClients.fulfilled, (state, action) => {
      state.groupsClients = action.payload
    })
  },
})

export const {
  dashboardValidationLoaded,
  dashboardValidationLoading,
  dashboardValidationReceived,
  dashboardValidationReset,
} = slice.actions

export default slice.reducer
