/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Form, Formik } from 'formik'
import CallInputs from './call_inputs.jsx'
import { getInitialValues, getValidationSchema } from './utils/util.js'
import { Heading } from '../ui/components'
import { Col, Row } from '../ui/layout/index.jsx'

interface ValuesProps {
  [key: string]: any
}
interface ItemProps {
  name: string,
  component: string | JSX.Element,
  title?: string | object,
  col: string | string[],
  placeholder?: string,
  isRequired?: boolean,
  tabIndex?: number,
  mask?: string,
  submittingText?: string,
  validations?: string,
  value?: any,
  type?: string,
  withDisable?: boolean,
  components?: any,
  mb?: number,
  mt?: number,
  display?: string | string[],
  step?: number | string,
}

interface FormFactoryParams {
  name?: string,
  component?: JSX.Element,
  items: ItemProps[],
}

interface FormFactoryProps {
  data: FormFactoryParams[],
  onSubmit: (values: ValuesProps, action: any) => void
}

const FormFactory = ({ data, onSubmit }: FormFactoryProps): JSX.Element => {
  const validationSchema = getValidationSchema(data)
  const initialValues = getInitialValues(data)

  const options = {
    enableReinitialize: true,
    initialValues,
    onSubmit,
  }

  if (validationSchema) Object.assign(options, { validationSchema })

  return (
    <Formik {...options}>
      {(actions) => (
        <Form>
          {data.map(({ name, component: Component, items }, idx) => (
            <div key={idx}>
              {name && name !== 'Faça seu cadastro' && !Component && <Heading>{name}</Heading>}
              <Row>
                {items.filter((item) => item.component).map(({ col, ...item }, index) => (
                  <Col
                    w={col || '100%'}
                    key={`${idx}-${index}`}
                    mb={item.mb || 15}
                    mt={item.mt}
                    d={item?.display || 'block'}
                  >
                    <CallInputs {...item} {...actions} />
                  </Col>
                ))}
              </Row>
            </div>
          ))}
        </Form>
      )}
    </Formik>
  )
}

export default FormFactory
